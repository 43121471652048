import React from "react"
import { graphql } from "gatsby"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { PillButton } from "../UI/PillButton"
import SectionHeading from "../UI/SectionHeading"
import {
  sectionClass,
  bgImgClass,
  bgOverlayClass,
  containerClass,
  contentColClass,
  textClass
} from "./BannerWithContent.module.scss"


export const fragment = graphql`
  fragment bannerWithContentFragment on WpPage_Flexlayouts_FlexibleLayouts_BannerWithContent {
    title
    shortDescription
    headingType
    button {
      target
      title
      url
    }
    backgroundImage {
      ...FullWidthImageQuery
    }
  }
`

const BannerWithContent = ({
  index,
  title,
  shortDescription,
  button,
  backgroundImage,
  headingType
}) => {
  const image = getImage(backgroundImage?.localFile)
  return (
    <section className={sectionClass} id="banner">
      <GatsbyImage
        className={bgImgClass}
        image={image}
        alt={backgroundImage?.altText}
        loading={index===0 ? 'eager' : 'lazy'}
      />
      <div className={bgOverlayClass} />
      <Container className={`${containerClass}`}>
        <Row>
          <Col className={contentColClass} sm={12} md={10} xl={9}>
            <SectionHeading className="py-3" text={title} type={headingType} />
            <p className={textClass}>
              {shortDescription}
            </p>
            {button && <PillButton link={button.url} title={button.title} />}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default BannerWithContent
